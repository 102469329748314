import React from 'react';
import Header from './Header'; // Your Header component
import MenuBarMain from './MenuBarMain'; // Your MenuBar component

const Layout = ({ children, company, title, h_bg_col, showMenu, showSiteBuilder }) => {
  return (
    <div>
      {/* Header is always shown */}
      <Header company={company || ''} header={title || ''} bg_col={h_bg_col} />

      {/* Conditionally render the MenuBar */}
      {showMenu && <MenuBarMain showSiteBuilder={showSiteBuilder} />} 

      <main>{children}</main>

      {/* Add footer if necessary */}
    </div>
  );
};

export default Layout;