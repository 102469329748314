import React, { useEffect, useRef } from 'react';

function GPTResponse({ text }) {
//    const [isVisible, setIsVisible] = useState(true);
    const textareaRef = useRef(null);

//    const toggleVisibility = () => {
//        setIsVisible(!isVisible);
//    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [text]);

    return (
        <div className="field">
           


                <div className="control mt-3">
                    <textarea 
                        className="textarea"
                        readOnly 
                        rows="10"
                        value={text}
                        ref={textareaRef}  // Attach the ref to the textarea
                    />
                </div>


        </div>
    );
}

export default GPTResponse;
