import { useState } from 'react';
import './GPT.css';


function GPTRequest ({onSubmit}) {
    const[term, setTerm] = useState('');
    const handleFormSubmit = (event) => {
        event.preventDefault();
        onSubmit(term);
        setTerm('');
    }
    
    // const handleChange = (event) => {
    //     setTerm(event.target.value);
    // }

    return (

        <div className="request-box">
        <form onSubmit={handleFormSubmit}>
            <div className="field"> 
                <label className="label"></label> 
                <div className="control">
                    <input 
                        className="input"
                        type="text"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                    />
                </div>
            </div>
        </form>          
    </div>
    );

}

export default GPTRequest;