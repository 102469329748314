import axios from 'axios';

const gptAPI = async (term, id) => {
      console.log("Making an API call")
      //const response = await axios.get('https://gpt-api.kindforest-c4412a66.eastus.azurecontainerapps.io/restaurant', {
        //const url='https://gpt-api.kindforest-c4412a66.eastus.azurecontainerapps.io/' + id;
        const url='http://localhost:8080/' + id;
        console.log('url:'+url)
        console.log('term:'+ term)
        const response = await axios.get(url, {
     
      params: {
        id: id,
        prompt: term
      }
    });

   return response.data;  
}

export default gptAPI;