import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import FAQ from './FAQ';
import Home from './Home';
import Layout from './Layout'; // Importing Layout with Header and MenuBar
import SiteBuilder from './SiteBuilder'; // Importing SiteBuilder

const App = () => {
  return (
    <Router>
      {/* Use the Layout with MenuBar visible */}
      <Layout company="Recursion AI" title="Elevate Customer Experience. Build AI-Powered Websites in days" showMenu={true} showSiteBuilder={true} h_bg_col="#001f3f">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/sitebuilder" element={<SiteBuilder />} />
          <Route path="*" element={<h1>404 - Page Not Found</h1>} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;