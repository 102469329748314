
import axios from 'axios';

const builder_api = async (term, id) => {
    try {
      console.log("Making an API call");
  
      // Call the backend service
      const url = `http://localhost:8080/website`;
      console.log('url: ' + url);
      console.log('term: ' + term);
  
      // Make the GET request
      const response = await axios.get(url, {
        params: {
          id: id,
          prompt: term
        }
      });
  
      // Assuming response.data is a String array
      const [llmResponse, functionCall] = response.data;
  
      console.log("LLM Response:", llmResponse);
      console.log("Function Call Properties:", functionCall);
  
      // If the second element is a valid JSON string, parse it
      let parsedProperties = {};
      if (functionCall) {
        try {
          parsedProperties = JSON.parse(functionCall);
        } catch (error) {
          console.error("Error parsing JSON properties:", error);
        }
      }
  
      // Return both LLM response and parsed properties
      return {
        llmResponse,
        parsedProperties
      };
  
    } catch (error) {
      console.error("Error making the API call:", error);
      throw error;
    }
  };
  export default builder_api;

 