import React, { useEffect, useState } from 'react';
import gptAPI from './api';
import './Bot.css';
import GPTRequest from './components/GPTRequest';
import GPTResponse from './components/GPTResponse';
//import UserContext from './context/user';

function Bot({ name, id }) {
    const [chatHistory, setChatHistory] = useState('');
    

  //  const {user, session, createSession} = useContext(UserContext);
    

    const handleSubmit = async (term) => {
        console.log('Calling GPT API with ', term);
        const result = await gptAPI(term,id);
        
        // Append the user's message and the GPT's response to the chat history
        const updatedHistory = chatHistory + "\nQ: " + term + "\nA: " + result;
        
        setChatHistory(updatedHistory);
        console.log('response updated', term);
    };

    const init = async () => {
       //console.log('Logged in User '+ user)
       //createSession();
        //const updatedHistory = "";
        //setChatHistory(updatedHistory);

    };    
    useEffect(() => {
        //console.log('Logged in User '+ user)
        //console.log('User session '+ session)
        //init();
        const welcomeMessage = "Welcome to Bollywood Glimpse Dance Academy. If you want to leave a message for Sarika for have any questions, please enter them here!"
        setChatHistory(welcomeMessage);
    },[]);
    


    return ( 
        <div className="bot-container">
            <section>
        
            </section>
            <div className="bot-header">
                <div className="subtitle">{name}</div>
            </div>
            
            <GPTResponse text={chatHistory} />
            <GPTRequest onSubmit={handleSubmit} />
           
        </div>
    );
}

export default Bot;
