import React, { useEffect, useRef, useState } from 'react';
import NewSiteBuilder from './NewSiteBuilder'; // Updated import
import builder_api from './builder_api';

function ConversationModal({ onClose }) {
  const [conversation, setConversation] = useState([]);
  const [builderState, setBuilderState] = useState({}); // Builder state to hold properties
  const conversationEndRef = useRef(null);  // For scrolling to the latest message
  const inputRef = useRef(null);  // For auto-focusing input

  const handleUserInput = async (term) => {
    console.log('Calling GPT API with ', term);
    
    // Call the API to get the LLM response and parsed properties
    const { llmResponse, parsedProperties } = await builder_api(term, "website");

    // Log parsed properties to check the values
    console.log("Parsed Properties:", parsedProperties);

    // Append the user's message and the GPT's response to the conversation
    if (llmResponse) {
      setConversation([...conversation, { user: term, response: llmResponse }]);
    }

    // Use a functional state update to ensure the new properties are merged with any existing state
    setBuilderState((prevState) => {
      const updatedState = {
        ...prevState,  // Keep previous state values
        ...parsedProperties  // Merge parsed properties into the state
      };
      console.log("Updated builderState:", updatedState);  // Log the updated state
      return updatedState;
    });
  };

  // Scroll to the bottom of the conversation whenever a new message is added
  useEffect(() => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation]);

  // Auto-focus the input field when the modal opens
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content" style={{ width: '80%', maxWidth: '1200px' }}> {/* Larger fixed width */}
        <div className="box" style={{ display: 'flex', height: '500px' }}>  {/* Fixed height for entire frame */}
          
          {/* Left side: New Site Builder component */}
          <div style={{ flex: '1', paddingRight: '20px', borderRight: '1px solid #ccc', overflowY: 'auto' }}>
            <h2 className="title">New Site Builder Preview</h2>
            
            {/* Log builderState before passing it to NewSiteBuilder */}
            {console.log("Passing builderState to NewSiteBuilder:", builderState)}

            {/* Pass builderState (parsedProperties) to NewSiteBuilder */}
            <NewSiteBuilder siteSettings={builderState} />
          </div>

          {/* Right side: Conversation Interface */}
          <div style={{ flex: '1', paddingLeft: '20px', display: 'flex', flexDirection: 'column' }}>
            <h2 className="title">Conversational Interface</h2>

            {/* Conversation history - Fixed height with scrollable content */}
            <div className="content" style={{ flex: '1', overflowY: 'auto', marginBottom: '20px', paddingRight: '10px' }}>
              {conversation.map((entry, index) => (
                <div key={index}>
                  <p><strong>User:</strong> {entry.user}</p>
                  <p><strong>Response:</strong> {entry.response}</p>
                </div>
              ))}
              {/* Invisible element to scroll to */}
              <div ref={conversationEndRef}></div>
            </div>

            {/* Input field for user to send messages */}
            <div className="field">
              <label className="label">Your request</label>
              <div className="control">
                <input
                  ref={inputRef}  // Auto-focus on this input
                  className="input"
                  type="text"
                  placeholder="Type your request"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.target.value.trim()) {
                      handleUserInput(e.target.value);
                      e.target.value = '';  // Clear input after sending
                    }
                  }}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
    </div>
  );
}

export default ConversationModal;