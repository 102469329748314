import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MessageTile from './MessageTile'; // Import the MessageTile component
import './QATiles.css';

const QATiles = () => {
  const [qaData, setQaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8080/questions');
        setQaData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="qa-tiles-container">
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      {!loading && !error && qaData.length > 0 && (
        qaData.map((qa, index) => (
          <div
            className={`qa-tile ${expandedIndex === index ? 'expanded' : ''}`}
            key={index}
            style={{ backgroundColor: '#add8e6' }}
          >
            <div className="qa-question" onClick={() => toggleExpand(index)}>
              <span>{qa.question}</span>
              <span className="arrow">{expandedIndex === index ? '▲' : '▼'}</span>
            </div>
            {expandedIndex === index && (
              <div className="qa-answer">
                {qa.answer}
              </div>
            )}
          </div>
        ))
      )}

      {/* Add the MessageTile component as the last tile */}
      <MessageTile />
    </div>
  );
};

export default QATiles;
