import React, { useEffect, useState } from 'react';
import "./BevelButton.css";
const ConfirmButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Set up the EventSource to listen to server-side events
    const eventSource = new EventSource('http://localhost:8080/subscribe');

    // Listen for the event sent by the server
    eventSource.onmessage = (event) => {
      console.log('Event received:', event.data);
      if (event.data === 'SHOW_CONFIRM_BUTTON') {
        setVisible(true); // Make the button visible when the correct event is received
      }
    };

    // Handle any error
    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      eventSource.close(); // Close the connection on error
    };

    // Clean up the EventSource when the component unmounts
    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div>
      {visible && <button className="bevel-button">Confirm</button>}
    </div>
  );
};

export default ConfirmButton;