import React from 'react';
import './MenuBar.css';
const MenuBar = ({ onNavigate }) => (
  <nav>
    <ul className="menu-bar">
      <li><button onClick={() => onNavigate('home')}>Home</button></li>
      <li><button onClick={() => onNavigate('faq')}>FAQ</button></li>
      {/* Add more menu items if needed */}
    </ul>
  </nav>
);

export default MenuBar;
