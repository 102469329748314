import 'bulma/css/bulma.min.css';
import React, { useEffect, useState } from 'react';

function Header({ company, header, bg_col }) {
  const [displayedText, setDisplayedText] = useState('');  // Track displayed characters
  const [index, setIndex] = useState(0);  // Index for the next character

  // Typewriter effect: Display one character at a time
  useEffect(() => {
    if (index < header.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + header[index]);
        setIndex(index + 1);  // Move to the next character
      }, 50);  // Speed of the typing effect, adjust as needed
      return () => clearTimeout(timeout);  // Cleanup timeout
    }
  }, [index, header]);

  return (
    <header className="hero" style={{ backgroundColor: bg_col || '#ffffff' }}>  {/* Apply dynamic bg_col */}
      <div className="hero-body has-text-centered">  {/* Center the text */}
        <h1 className="title" style={{ color: '#FFD700', marginBottom: '10px' }}>  {/* Gold color for company */}
          {company}
        </h1>
        <div className="subtitle is-italic" style={{ color: '#FFFFFF' }}>  {/* Italicize the header */}
          {displayedText}  {/* Display the progressively typed out header */}
        </div>
      </div>
    </header>
  );
}

export default Header;
