import React, { useState } from 'react';
import FAQ from './FAQ'; // Import FAQ component
import Home from './Home'; // Import Home component
import Layout from './Layout'; // Import Layout
import MenuBar from './MenuBar'; // Import MenuBar component

const SiteBuilder = () => {
  const [title, setTitle] = useState('My Site Preview');
  const [faqs, setFaqs] = useState([{ question: 'What is this?', answer: 'A site preview builder!' }]);
  const [currentPage, setCurrentPage] = useState('home');  // Track the current page

  const handleTitleChange = (e) => setTitle(e.target.value);

  const handleFaqChange = (index, field, value) => {
    const newFaqs = [...faqs];
    newFaqs[index][field] = value;
    setFaqs(newFaqs);
  };

  const handleNavigate = (page) => {
    setCurrentPage(page);  // Change the current page when a menu item is clicked
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return <Home />;
      case 'faq':
        return <FAQ faqs={faqs} />;
      default:
        return <Home />;
    }
  };

  return (
    <div>
      <h2>Site Builder</h2>

      {/* User inputs for dynamic content */}
      <div>
        <label>Site Title: <input type="text" value={title} onChange={handleTitleChange} /></label>
        {/* Add FAQ input fields or other inputs here */}
      </div>

      {/* Preview with Layout (with internal navigation and preview) */}
      <h2>Preview</h2>
      <Layout title={title} showMenu={false} showSiteBuilder={false}>
        <MenuBar onNavigate={handleNavigate} />  {/* Now MenuBar is defined */}
        <div className="preview-content">
          {renderPage()}
        </div>
      </Layout>
    </div>
  );
};

export default SiteBuilder;