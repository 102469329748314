import 'bulma/css/bulma.min.css';
import React, { useState } from 'react';
import ConversationModal from './ConversationalModel';
import './Home.css'; // Custom styles for height and button alignment
function Home() {
  const [isSiteBuilderVisible, setSiteBuilderVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  // Handle button click to show SiteBuilder
  /*const handleStartClick = (option) => {
    setSelectedOption(option);  // Set which option was selected
    setSiteBuilderVisible(true);  // Show SiteBuilder below the Home component
  };*/
  const handleStartClick = () => {
    setModalVisible(true);  // Show the modal
  };
  return (
    <main className="section has-background-light">
      <div className="container">
        <div className="columns is-variable is-8">  {/* Responsive columns for the cards */}
          
          {/* Card 1: Build your AI Website */}
          <div className="column">
            <div className="card card-equal-height">
              <div className="card-content">
                <p className="title is-4">
                  Build your AI Website
                </p>
                <p className="subtitle is-5 has-text-grey-dark">
                  Create an AI-powered website in minutes!
                </p>
              </div>
              <div className="card-footer">
                <button
                  className="button is-info"
                  onClick={() => handleStartClick('AI Website')}  // Trigger SiteBuilder
                >
                  Start
                </button>
              </div>
            </div>
          </div>

          {/* Card 2: Build an Executive Assistant */}
          <div className="column">
            <div className="card card-equal-height">
              <div className="card-content">
                <p className="title is-4">
                  Build an AI enabled Profile
                </p>
                <p className="subtitle is-5 has-text-grey-dark">
                Share your insights with audiences through your AI persona! 
                </p>
              </div>
              <div className="card-footer">
                <button
                  className="button is-success"
                  onClick={() => handleStartClick('Executive Assistant')}  // Trigger SiteBuilder
                >
                  Start
                </button>
              </div>
            </div>
          </div>

          {/* Card 3: Build an Intelligent FAQ */}
          <div className="column">
            <div className="card card-equal-height">
              <div className="card-content">
                <p className="title is-4">
                  Build an Intelligent FAQ
                </p>
                <p className="subtitle is-5 has-text-grey-dark">
                  Enhance customer support with AI-powered FAQs.
                </p>
              </div>
              <div className="card-footer">
                <button
                  className="button is-primary"
                  onClick={() => handleStartClick('Intelligent FAQ')}  // Trigger SiteBuilder
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for the conversational request-response component */}
      {isModalVisible && (
        <ConversationModal onClose={() => setModalVisible(false)} />
      )}
    </main>
  );
}

export default Home;



