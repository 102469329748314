
import { Link } from 'react-router-dom';
import './MenuBar.css';

const MenuBarMain = ({ showSiteBuilder }) => (
  <nav>
    <ul className="menu-bar">
      <li><Link to="/">Home</Link></li>
      <li><Link to="/faq">FAQ</Link></li>
      
      {/* Conditionally render the SiteBuilder menu item */}
      {showSiteBuilder && <li><Link to="/sitebuilder">Site Builder</Link></li>}
    </ul>
  </nav>
);

export default MenuBarMain; 

