import React from 'react';

const NewSiteBuilder = ({ siteSettings = {} }) => {
  // Destructure siteSettings to make accessing properties easier
  const {
    background_color = 'white',
    font = 'Arial',
    header = 'Welcome to My Site',
    menu = ['Home', 'About Us', 'Services', 'Contact'],
    footerText = '© 2024 My Website. All Rights Reserved.',
    contentSections = [
      {
        title: 'About Us',
        body: 'We are a company that values innovation and excellence in everything we do.'
      },
      {
        title: 'Services',
        body: 'We offer a wide range of services to meet your needs.'
      }
    ]
  } = siteSettings;

  return (
    <div
      style={{
        backgroundColor: background_color,
        fontFamily: font
      }}
    >
      {/* Header Section */}
      <header
        style={{
          backgroundColor: '#333',
          color: '#fff',
          padding: '10px 20px',
          textAlign: 'center'
        }}
      >
        <h1>{header}</h1>
      </header>

      {/* Navigation Menu */}
      <nav
        style={{
          backgroundColor: '#444',
          color: '#fff',
          padding: '10px 20px',
          textAlign: 'center'
        }}
      >
        <ul
          style={{
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {menu.map((menuItem, index) => (
            <li
              key={index}
              style={{
                margin: '0 15px',
                cursor: 'pointer'
              }}
            >
              {menuItem}
            </li>
          ))}
        </ul>
      </nav>

      {/* Main Content Section */}
      <main
        style={{
          padding: '20px'
        }}
      >
        {contentSections.map((section, index) => (
          <section key={index} style={{ marginBottom: '40px' }}>
            <h2 style={{ color: '#333' }}>{section.title}</h2>
            <p>{section.body}</p>
          </section>
        ))}
      </main>

      {/* Footer Section */}
      <footer
        style={{
          backgroundColor: '#333',
          color: '#fff',
          textAlign: 'center',
          padding: '10px 20px',
          position: 'fixed',
          bottom: 0,
          width: '100%'
        }}
      >
        <p>{footerText}</p>
      </footer>
    </div>
  );
};

export default NewSiteBuilder;


