import React, { useState } from 'react';
import Bot from './Bot';
import ConfirmButton from './ConfirmButton';
import './QATiles.css'; // Optional CSS if needed

const MessageTile = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`qa-tile ${expanded ? 'expanded' : ''}`} style={{ backgroundColor: '#f0e68c' }}>
      <div className="qa-question" onClick={toggleExpand}>
        <span>Leave Me a Message</span>
        <span className="arrow">{expanded ? '▲' : '▼'}</span>
      </div>

      {/* When expanded, show the MessageForm component */}
      {expanded && (
        <div className="qa-answer">
          <Bot name="Sarika's Assistant" id="enrollment"/>
          <ConfirmButton></ConfirmButton>
        
        </div>
      )}
    </div>
  );
};

export default MessageTile;
